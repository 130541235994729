const master_menu = [
    {
        key: 'sidebar.item.sys_admin',
        icon: 'zmdi-ticket-star',
        route: '/sysadmin',
    },
    {
        key: 'sidebar.item.robot_types',
        icon: 'zmdi-account-add',
        route: '/robottypes',
    },
    {
        key: 'sidebar.item.app_types',
        icon: 'zmdi-collection-video',
        route: '/apptypes',
    },
    {
        key: 'sidebar.item.platform_plugins',
        icon: 'zmdi-puzzle-piece',
        route: '/plugintypes',
    },
    {
        key: 'sidebar.item.platform_tools',
        icon: 'zmdi-wrench',
        route: '/platform_tools',
    },
    {
        key: 'sidebar.item.platform_modules',
        icon: 'zmdi-widgets',
        route: '/platform_modules',
    },
    {
        key: 'sidebar.item.movements',
        icon: 'zmdi-run',
        route: '/movements',
    },
    {
        key: 'sidebar.item.extractions',
        icon: 'zmdi-spellcheck',
        route: '/extractions',
    },
    {
        key: 'sidebar.item.visual_templates',
        icon: 'zmdi-format-color-fill',
        route: '/visual_templates',
    },
    {
        key: 'sidebar.item.rc_settings',
        icon: 'zmdi-cloud-circle',
        route: '/rc3_settings',
    },
    {
        key: 'sidebar.item.robot_services',
        icon: 'zmdi-cloud-circle',
        route: '/robot_services',
    },
    {
        key: 'sidebar.item.pluginstore',
        icon: 'zmdi-case-play',
        route: '/pluginstore/apps',
    },
];

const super_menu = [
    {
        key: 'sidebar.item.pluginspaces',
        icon: 'zmdi-layers',
        route: '/admin/pluginspaces',
    },
    // {
    //     key: 'sidebar.item.accounts',
    //     icon: 'zmdi-balance',
    //     route: '/admin/billing',
    // },
    {
        key: 'sidebar.item.analytics',
        icon: 'zmdi-trending-up',
        route: '/admin/analytics',
    },
    {
        key: 'sidebar.item.robot_defaults',
        icon: 'zmdi-folder-person',
        route: '/robotdefaults',
    },
    // {
    //     key: 'sidebar.item.rc_settings',
    //     icon: 'zmdi-cloud-circle',
    //     route: '/rc3_clients',
    // },
];

const trainer_menu = [
    {
        key: 'sidebar.item.qna_settings',
        icon: 'zmdi-comments',
        route: '/pluginbot_qna',
    },
];

const admin_menu = [
    {
        key: 'sidebar.item.pluginspace_settings',
        icon: 'zmdi-settings',
        route: '/pluginspace/settings',
    },
    // {
    //     key: 'sidebar.item.pluginspace_billing',
    //     icon: 'zmdi-balance',
    //     route: '/pluginspace/billing',
    // },
    {
        key: 'sidebar.item.pluginspace_locations',
        icon: 'zmdi-pin-drop',
        route: '/pluginspace/locations',
    },
    {
        key: 'sidebar.item.pluginspace_groups',
        icon: 'zmdi-accounts',
        route: '/pluginspace/groups',
    },
    {
        key: 'sidebar.item.users',
        icon: 'zmdi-account',
        route: '/pluginspace/users',
    },
];

const group_menu = [
    {
        key: 'sidebar.item.groups',
        icon: 'zmdi-accounts',
        route: '/groups',
    },
];

const group_submenu = {
    admin: [
        {
            key: 'sidebar.item.group_users',
            route: '/groups/{id}/users',
            icon: 'zmdi-account',
        },
        {
            key: 'sidebar.item.group_locations',
            route: '/groups/{id}/locations',
            icon: 'zmdi-pin-drop',
        },
        {
            key: 'sidebar.item.group_sections',
            route: '/groups/{id}/sections',
            icon: 'zmdi-view-module',
        },
    ],
    viewer: [
        {
            key: 'sidebar.item.sections',
            route: '/sections',
            icon: 'zmdi-view-module',
        },
    ],
};

const user_menu = [
    {
        key: 'sidebar.item.dashboard',
        icon: 'zmdi-view-dashboard',
        route: '/dashboard',
    },
    {
        key: 'sidebar.item.robots',
        icon: 'zmdi-android',
        route: '/robots',
    },
    {
        key: 'sidebar.item.galleries',
        icon: 'zmdi-collection-image',
        route: '/galleries',
    },
    {
        key: 'sidebar.item.tools',
        icon: 'zmdi-wrench',
        route: '/tools',
    },
    {
        key: 'sidebar.item.plugins',
        icon: 'zmdi-puzzle-piece',
        route: '/plugins',
    },
    {
        key: 'sidebar.item.applications',
        icon: 'zmdi-play-circle',
        route: '/applications',
    },
    {
        key: 'sidebar.item.recycle_bin',
        icon: 'zmdi-delete',
        route: '/recycle_bin',
    },
    // {
    //     label: 'Documentation',
    //     icon: 'pi pi-fw pi-question',
    //     command: () => {
    //         history.push('/documentation');
    //     },
    // },
];

export default function createMenu(permission) {
    return {
        master_menu: ['master'].includes(permission) ? master_menu : null,
        super_menu: ['master', 'super_admin'].includes(permission)
            ? super_menu
            : null,
        trainer_menu: ['master', 'super_admin'].includes(permission)
            ? trainer_menu
            : null,
        admin_menu: ['master', 'super_admin', 'admin'].includes(permission)
            ? admin_menu
            : null,
        group_menu,
        group_submenu,
        user_menu,
    };
}
