/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdDelete } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';

import { DialogContentText, Menu, MenuItem } from '@material-ui/core';

import CardItem from '~/components/CardItem';
import DataFilter from '~/components/DataFilter';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import PageContent from '~/components/PageContent';
import SimpleDialog from '~/components/SimpleDialog';
import ViewSwitcher from '~/components/ViewSwitcher';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import checkAdmin from '~/util/CheckAdmin';
// import generateDownloadableFile from '~/util/GenerateDownloadableFile';
import getDateLocale from '~/util/GetDateLocale';
// import GetFileName from '~/util/GetFileName';

const PAGE_KEY = 'autonomous_actions';
const PAGE_VIEW_DEF = 'grid';

export default function AutonomousMode() {
    const dispatch = useDispatch();

    const settings = useSelector(state => state.settings || null);
    const page_settings = useSelector(state => state.pages || null);
    const curr_page_settings =
        page_settings[PAGE_KEY] && typeof page_settings[PAGE_KEY] === 'object'
            ? page_settings[PAGE_KEY]
            : {};

    const { profile } = useSelector(state => state.user || null);

    const super_user = checkAdmin(settings, profile);

    const date_loc = getDateLocale(settings);
    const { active } = settings;
    const allowNew = !(active && active.id === '*');

    const [isLoading, setIsLoading] = useState(false);
    const [forbidden, setForbidden] = useState(false);
    const [view, setView] = useState(
        curr_page_settings && curr_page_settings.view
            ? curr_page_settings.view
            : PAGE_VIEW_DEF
    );

    const [items, setItems] = useState([]);
    const [filteredData, setFilteredData] = useState(items);

    const [currItem, setCurrItem] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const open = Boolean(anchorEl);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadItems() {
        setIsLoading(true);
        await api
            .get(`autonomous_mode`)
            .then(response => {
                const data = response.data.map(s => {
                    const created = new Date(s.created);
                    const updated = new Date(s.updated);

                    return {
                        ...s,
                        created: formatDistance(created, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                        created_timestamp: created.toISOString(),
                        updated: formatDistance(updated, new Date(), {
                            addSuffix: true,
                            locale: date_loc,
                        }),
                        updated_timestamp: updated.toISOString(),
                        group_name: (s.group && s.group.name) || (
                            <LocaleMessage msg="message.all_groups.short" />
                        ),
                        robot_type: s.robot ? s.robot.name : '---',
                    };
                });
                setItems(data);
            })
            .catch(error => requestError(error));
        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    useEffect(() => {
        loadItems();
    }, [active, date_loc]);

    async function deletePack() {
        setIsLoading(true);
        setDeleteDialogOpen(false);
        await api
            .delete(`autonomous_mode/${currItem}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.tools.autonomous_actions.list.delete_success" />
                );
                await loadItems();
            })
            .catch(error => requestError(error));
        setIsLoading(false);
    }

    function goToSettings(event, _id) {
        event.preventDefault();
        setAnchorEl(null);
        history.push(`/tools/autonomous_actions/${_id}`);
    }

    function handleClick(event, _id) {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setCurrItem(_id);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    // TO DO
    // async function handleExport(event, _id) {
    //     setAnchorEl(null);
    //     setIsLoading(true);
    //     const currPack = items.find(s => {
    //         return s.id === _id;
    //     });
    //     toast.info(<LocaleMessage msg="message.generating_file" />);
    //     const filename = GetFileName(
    //         `AUTONOMOUS-ACTIONS-${currPack.name}`,
    //         'json'
    //     );

    //     await api
    //         .get(`autonomous_mode/${_id}/export`)
    //         .then(async response => {
    //             const config = response.data;
    //             generateDownloadableFile(event, {
    //                 filename,
    //                 data: config,
    //             });
    //         })
    //         .catch(error => requestError(error));
    //     setIsLoading(false);
    // }

    function handleDeleteOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setAnchorEl(null);
        setDeleteDialogOpen(true);
    }

    function handleClickOpen(event, _id) {
        setCurrItem(_id);
        event.preventDefault();
        setAnchorEl(null);
        setDeleteDialogOpen(true);
    }

    function handleDeleteDialogClose(event) {
        event.preventDefault();
        setDeleteDialogOpen(false);
    }

    function handleTableRowClick(event, id) {
        event.preventDefault();
        setCurrItem(id);
        history.push(`/tools/autonomous_actions/${id}`);
    }

    function autonomousPackCard(a) {
        return (
            <div className="col-md-3 col-sm-6 mb-5" key={a.id}>
                <Link to={`/tools/autonomous_actions/${a.id}`}>
                    <CardItem
                        title={a.name}
                        optionsClick={event => handleClick(event, a.id)}
                        updated={a.updated}
                        position="center"
                        group={a.group_name}
                        open_all={a.open_all}
                    >
                        <>
                            <div className="card-subtitle">
                                <small>{a.robot_type}</small>
                            </div>
                            <p
                                className="card-text"
                                style={{ minHeight: '30px' }}
                            >
                                <small>{a.description}</small>
                            </p>
                        </>
                    </CardItem>
                </Link>
            </div>
        );
    }

    function buildGridView() {
        return (
            <>
                <div
                    className="row"
                    style={{ minHeight: '150px', paddingTop: '15px' }}
                >
                    {filteredData.map(s => autonomousPackCard(s))}
                </div>
                <Menu
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleMenuClose}
                    keepMounted
                >
                    <MenuItem onClick={e => goToSettings(e, currItem)}>
                        <LocaleMessage msg="button.settings" />
                    </MenuItem>
                    {/* <MenuItem onClick={e => handleExport(e, currItem)}>
                        <LocaleMessage msg="button.download_file" />
                    </MenuItem> */}
                    <MenuItem onClick={e => handleClickOpen(e, currItem)}>
                        <LocaleMessage msg="button.delete" />
                    </MenuItem>
                </Menu>
            </>
        );
    }

    function buildDeleteDialog() {
        return (
            <SimpleDialog
                open={deleteDialogOpen}
                onClose={handleDeleteDialogClose}
                title={
                    <LocaleMessage msg="page.tools.autonomous_actions.list.delete_title" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.recycler.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDeleteDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'remove',
                        onClick: () => deletePack(currItem),
                        label: <LocaleMessage msg="button.remove" />,
                    },
                ]}
            />
        );
    }

    function buildListView() {
        const headCells = [
            {
                id: 'group_name',
                label: <LocaleMessage msg="table.headers.group" />,
            },
            {
                id: 'name',
                label: <LocaleMessage msg="table.headers.name" />,
            },
            {
                id: 'robot_type',
                label: <LocaleMessage msg="table.headers.robot_type" />,
            },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [
            // {
            //     id: 'export',
            //     label: <LocaleMessage msg="button.export_settings" />,
            //     icon: <MdOutlineFileDownload />,
            //     action: handleExport,
            // },
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: handleDeleteOpen,
            },
        ];

        return (
            <>
                <div
                    className="mb-5"
                    style={{
                        minHeight: '150px',
                        width: '100%',
                        padding: '15px',
                    }}
                >
                    <DataTable
                        headerColumns={headCells}
                        data={filteredData}
                        pageKey={`${PAGE_KEY}_list`}
                        orderColumn="name"
                        orderDirection="asc"
                        setCurrItem={_id => setCurrItem(_id)}
                        handleTableRowClick={(event, id) =>
                            handleTableRowClick(event, id)
                        }
                        rowActions={rowActions}
                    />
                </div>
            </>
        );
    }

    return (
        <PageContent
            title={
                <LocaleMessage msg="page.tools.autonomous_actions.list.title" />
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/tools',
                    title: <LocaleMessage msg="breadcrumbs.tools" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {deleteDialogOpen ? buildDeleteDialog() : null}
                <div
                    className="body-top-controls"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <div className="col-3 pl-0">
                        <NewItemButton
                            disabled={!allowNew && !super_user}
                            link="/tools/autonomous_actions/new"
                            text={
                                <LocaleMessage msg="page.tools.autonomous_actions.list.add" />
                            }
                        />
                    </div>
                    <div className="col-3">
                        <DataFilter
                            rawData={items}
                            headerColumns={['name', 'robot_type']}
                            returnFilteredData={f_data =>
                                setFilteredData(f_data)
                            }
                        />
                    </div>
                    <ViewSwitcher
                        size="col-3"
                        view={view}
                        setView={v => setView(v)}
                        pageKey={PAGE_KEY}
                    />
                </div>
                {view === 'grid' ? buildGridView() : buildListView()}
            </>
        </PageContent>
    );
}
