/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
// import InjectIntl from 'react-intl-inject';
import OtpInput from 'react-otp-input';

import PropTypes from 'prop-types';

import {
    TextField,
    IconButton,
    Typography,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core';

import FormSwitch from '~/components/Form/Switch';
import languages from '~/components/LanguageSwitcher/data';
import LocaleMessage from '~/components/LocaleMessage';

import { ParameterArea } from '../../styles';

const limit = 6;

const request_modes = [
    {
        label: <LocaleMessage msg="list.telepresence.request.mode.broadcast" />,
        value: 'broadcast',
    },
    {
        label: <LocaleMessage msg="list.telepresence.request.mode.contact" />,
        value: 'contact',
    },
];

export default function FuncionalityParameters({ settings, setSettings }) {
    const {
        language,
        password,
        allow_requests,
        request_mode,
        broadcast_text,
        same_tab,
        enable_sounds,
        screensaver_timeout,
    } = settings;
    const [pwVisible, setPWVisible] = useState(!password);

    return (
        <ParameterArea>
            <div className="row mt-3">
                <div className="col-12 row">
                    <div className="col-6 mb-3">
                        <FormControl fullWidth>
                            <InputLabel>
                                <LocaleMessage msg="page.applications.telepresence.form.language" />
                            </InputLabel>
                            <Select
                                required
                                value={language || ''}
                                onChange={event =>
                                    setSettings('language', event.target.value)
                                }
                            >
                                {languages.map(l => {
                                    return l ? (
                                        <MenuItem value={l.code} key={l.code}>
                                            <LocaleMessage
                                                msg={`list.languages.${l.code}`}
                                            />
                                        </MenuItem>
                                    ) : null;
                                })}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-6 mb-3">
                        <TextField
                            label={
                                <LocaleMessage msg="page.applications.telepresence.form.screensaver_timeout" />
                            }
                            helperText={
                                <LocaleMessage msg="page.applications.telepresence.form.screensaver_timeout.helper" />
                            }
                            fullWidth
                            value={screensaver_timeout || '0'}
                            onChange={event => {
                                setSettings(
                                    'screensaver_timeout',
                                    event.target.value
                                );
                            }}
                            type="number"
                            // placeholder={30}
                            inputProps={{
                                min: '0',
                                step: '10',
                            }}
                        />
                    </div>
                </div>
                <div
                    className="col-12 row mb-3"
                    style={{ display: 'flex', alignItems: 'center' }}
                >
                    <Typography className="col-6" variant="body1">
                        <LocaleMessage msg="page.applications.telepresence.form.setup_password" />
                    </Typography>
                    <div
                        className="col-6"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <OtpInput
                            value={password || ''}
                            numInputs={limit}
                            separator={<span>-</span>}
                            isInputNum
                            isInputSecure={!pwVisible}
                            inputStyle={{
                                fontSize: '3vh',
                            }}
                            onChange={value => setSettings('password', value)}
                            hasErrored={!password || password.length !== limit}
                            errorStyle={{
                                border: '1px solid #f00',
                            }}
                        />
                        <IconButton
                            onClick={() => setPWVisible(!pwVisible)}
                            edge="end"
                        >
                            {pwVisible ? <MdVisibilityOff /> : <MdVisibility />}
                        </IconButton>
                    </div>
                </div>
                <div className="col-12">
                    <FormSwitch
                        value={enable_sounds || false}
                        onChange={event =>
                            setSettings('enable_sounds', event.target.checked)
                        }
                        label={
                            <LocaleMessage msg="page.applications.telepresence.form.enable_sounds" />
                        }
                    />
                </div>
                <div className="col-12">
                    <FormSwitch
                        value={same_tab || false}
                        onChange={event =>
                            setSettings('same_tab', event.target.checked)
                        }
                        label={
                            <LocaleMessage msg="page.applications.telepresence.form.same_tab" />
                        }
                    />
                </div>
                <div className="col-12">
                    <FormSwitch
                        value={allow_requests || false}
                        onChange={event =>
                            setSettings('allow_requests', event.target.checked)
                        }
                        label={
                            <LocaleMessage msg="page.applications.telepresence.form.allow_requests" />
                        }
                    />
                </div>
                {allow_requests ? (
                    <div className="col-12 row">
                        <div className="col-md-6 col-12">
                            <FormControl className="col-12 mb-5" fullWidth>
                                <InputLabel>
                                    <LocaleMessage msg="page.applications.telepresence.form.request.mode" />
                                </InputLabel>
                                <Select
                                    required
                                    value={request_mode || 'broadcast'}
                                    onChange={event =>
                                        setSettings(
                                            'request_mode',
                                            event.target.value
                                        )
                                    }
                                >
                                    {request_modes.map(m => {
                                        return m ? (
                                            <MenuItem
                                                value={m.value}
                                                key={m.value}
                                            >
                                                {m.label}
                                            </MenuItem>
                                        ) : null;
                                    })}
                                </Select>
                            </FormControl>
                        </div>
                        {!request_mode || request_mode === 'broadcast' ? (
                            <div className="col-md-6 col-12">
                                <TextField
                                    className="mb-5"
                                    label={
                                        <LocaleMessage msg="page.applications.telepresence.form.request.text" />
                                    }
                                    fullWidth
                                    value={broadcast_text || ''}
                                    onChange={event => {
                                        setSettings(
                                            'broadcast_text',
                                            event.target.value
                                        );
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </div>
        </ParameterArea>
    );
}

FuncionalityParameters.defaultProps = {
    settings: {},
};

FuncionalityParameters.propTypes = {
    settings: PropTypes.object,
    setSettings: PropTypes.func.isRequired,
};
