/* eslint-disable import-helpers/order-imports */
import React from 'react';
import { Switch } from 'react-router-dom';

// MASTER Admin Pages
import ActionForm from '~/pages/Master/Actions/Form';
import AppTypeForm from '~/pages/Master/AppTypes/Form';
import AppTypes from '~/pages/Master/AppTypes/List';
import ExtractionForm from '~/pages/Master/Extractions/Form';
import Extractions from '~/pages/Master/Extractions/List';
import PlatformToolForm from '~/pages/Master/PlatformTools/Form';
import PlatformTools from '~/pages/Master/PlatformTools/List';
import PlatformModulesForm from '~/pages/Master/PlatformModules/Form';
import PlatformModules from '~/pages/Master/PlatformModules/List';
import PluginstoreForm from '~/pages/Master/Pluginstore/Form';
import Pluginstore from '~/pages/Master/Pluginstore/List';
import PluginTemplateForm from '~/pages/Master/Plugintemplates/Form';
import PluginTypeForm from '~/pages/Master/Plugintypes/Form';
import PluginTypes from '~/pages/Master/Plugintypes/List';
import RC3Settings from '~/pages/Master/RC3Settings/Admin';
import RobotServices from '~/pages/Master/RobotServices/Admin';
import RC3Clients from '~/pages/Master/RC3Settings/List';
import VisualTemplateForm from '~/pages/Master/VisualTemplates/Form';
import VisualTemplates from '~/pages/Master/VisualTemplates/List';
import RobotTypes from '~/pages/Master/RobotTypes/List';
import RobotTypeForm from '~/pages/Master/RobotTypes/Form';
import SysAdmin from '~/pages/Master/SysAdmin';

// SuperAdmin Pages
import SUBilling from '~/pages/SuperAdmin/Billing';
import RobotMovements from '~/pages/SuperAdmin/Movements/List';
import RobotDefaultsForm from '~/pages/SuperAdmin/RobotDefaults/Form';
import RobotDefaults from '~/pages/SuperAdmin/RobotDefaults/List';
import RobotDefaultTypes from '~/pages/SuperAdmin/RobotDefaults/Types';
import SUAnalytics from '~/pages/SuperAdmin/SU_Analytics/Dashboard';
import SUList from '~/pages/SuperAdmin/SU_Analytics/List';
import SUPluginspaceForm from '~/pages/SuperAdmin/SU_Analytics/Pluginspaces/Form';
import SUPluginspaces from '~/pages/SuperAdmin/SU_Analytics/Pluginspaces/List';
import SURobotForm from '~/pages/SuperAdmin/SU_Analytics/Robots/Form';
import QnA from '~/pages/SuperAdmin/QnA';
import QnATemplates from '~/pages/SuperAdmin/QnA/Templates/List';
import QnATemplateForm from '~/pages/SuperAdmin/QnA/Templates/Form';
import QnATraining from '~/pages/SuperAdmin/QnA/Training';
import QnATrainingForm from '~/pages/SuperAdmin/QnA/Training/Form';
import QnAUsage from '~/pages/SuperAdmin/QnA/Usage';

// Pluginspace Admin Pages
import PluginspaceGroupForm from '~/pages/Pluginspaces/Groups/Form';
import PluginspaceGroups from '~/pages/Pluginspaces/Groups/List';
import PluginspaceGroupUsersForm from '~/pages/Pluginspaces/Groups/UserForm';
import PluginspaceLocationForm from '~/pages/Pluginspaces/Locations/Form';
import PluginspaceLocations from '~/pages/Pluginspaces/Locations/List';
import PluginspaceLocationZoneForm from '~/pages/Pluginspaces/Locations/Zones';
import PluginspaceForm from '~/pages/Pluginspaces/Settings';
import PluginspaceBilling from '~/pages/Pluginspaces/Billing';
import PluginspaceUserForm from '~/pages/Pluginspaces/Users/Form';
import PluginspaceUsers from '~/pages/Pluginspaces/Users/List';

// Group Admin Pages
import GroupLocationForm from '~/pages/Groups/Locations/Form';
import GroupLocations from '~/pages/Groups/Locations/List';
import GroupLocationZoneForm from '~/pages/Groups/Locations/Zones';
import GroupSectionsForm from '~/pages/Groups/Sections/Form';
import GroupSections from '~/pages/Groups/Sections/List';
import GroupUsersForm from '~/pages/Groups/Users/Form';
import GroupUsers from '~/pages/Groups/Users/List';

// Platform Open Pages
import Login from '~/pages/Open/Login';
import Page404 from '~/pages/Open/Page404';
import RecoverPassword from '~/pages/Open/RecoverPassword';
import ResetPassword from '~/pages/Open/ResetPassword';
import SignUp from '~/pages/Open/SignUp';

// Platform Pages
import AppConfig from '~/pages/Platform/AppConfig';
import ApplicationAPIkey from '~/pages/Platform/Applications/APIkeys';
import ApplicationForm from '~/pages/Platform/Applications/Form';
import Applications from '~/pages/Platform/Applications/List';
import AutonomousModeActions from '~/pages/Platform/AutonomousMode/Actions';
import AutonomousMode from '~/pages/Platform/AutonomousMode/List';
import AutonomousModeForm from '~/pages/Platform/AutonomousMode/Form';
import ContactListForm from '~/pages/Platform/Contacts/ContactLists/Form';
import ContactForm from '~/pages/Platform/Contacts/Contacts/Form';
import Contacts from '~/pages/Platform/Contacts';
import Dashboard from '~/pages/Platform/Dashboard';
import DialogForm from '~/pages/Platform/Dialogs/Form';
import DialogInfo from '~/pages/Platform/Dialogs/Info';
import QnANodes from '~/pages/Platform/QnATree/v2';
import Dialogs from '~/pages/Platform/Dialogs/List';
import GalleryForm from '~/pages/Platform/Galleries/Form';
import Galleries from '~/pages/Platform/Galleries/List';
import InfoFormatterPrivateForm from '~/pages/Platform/InfoFormatters/Form';
import InfoFormatters from '~/pages/Platform/InfoFormatters/List';
import MyGroups from '~/pages/Platform/MyGroups/List';
import SectionsForm from '~/pages/Platform/Sections/Form';
import MySections from '~/pages/Platform/Sections/List';
import NavMapForm from '~/pages/Platform/NavMaps/Form';
import NavMapIllustration from '~/pages/Platform/NavMaps/Illustration';
import NavMapLayerForm from '~/pages/Platform/NavMaps/Layers';
import NavMaps from '~/pages/Platform/NavMaps/List';
import NavMapPointForm from '~/pages/Platform/NavMaps/Points';
import NavMapLayerQR from '~/pages/Platform/NavMaps/QRCodes';
import PluginspaceTools from '~/pages/Platform/Tools/List';
import PluginspacePluginconfig from '~/pages/Platform/Plugins/Form';
import PluginspacePlugins from '~/pages/Platform/Plugins/List';
import PluginSurveyStepForm from '~/pages/Platform/Plugins/Survey/Form';
import PluginSurveySteps from '~/pages/Platform/Plugins/Survey/List';
import PluginDances from '~/pages/Platform/Plugins/Dance';
import PluginAnswersForm from '~/pages/Platform/Plugins/Answers/Form';
import PluginAnswers from '~/pages/Platform/Plugins/Answers/List';
import PresentationContents from '~/pages/Platform/PresentationContents/List';
import PresentationContentForm from '~/pages/Platform/PresentationContents/Form';
import Profile from '~/pages/Platform/Profile';
import Reports from '~/pages/Platform/Reports';
import RobotForm from '~/pages/Platform/Robots/Form';
import Robots from '~/pages/Platform/Robots/List';
import RecycleBin from '~/pages/Platform/RecycleBin';
import DanceForm from '~/pages/Platform/Dances/Form';
import Dances from '~/pages/Platform/Dances/List';
import TransferForm from '~/pages/Platform/Transfers/Form';
import ScriptForm from '~/pages/Platform/Scripts/Form';
import Scripts from '~/pages/Platform/Scripts/List';
import ScriptStepForm from '~/pages/Platform/Scripts/Steps';

// RC3 Pages
import RC3Main from '~/pages/RC3';

// SU View Pages
import SUView from '~/pages/SUView';

// Local CMD Pages
import LocalCMD from '~/pages/LocalCMD';

// Route
import Route from './Route';

export default function Router() {
    return (
        <Switch>
            {/* 
                =================================================================
                                        LOCAL ROUTES 
                =================================================================
            */}
            <Route
                path="/apps/local_conn/:id"
                component={LocalCMD}
                layout="local"
                isPrivate
            />
            {/* 
                =================================================================
                                        OPEN ROUTES 
                =================================================================
            */}
            <Route path="/login" component={Login} />
            <Route path="/register" component={SignUp} />
            <Route path="/recover/:id" component={RecoverPassword} />
            <Route path="/reset_password/:token" component={ResetPassword} />
            {/* 
                =================================================================
                                        RC3 ROUTES 
                =================================================================
            */}
            <Route
                path="/apps/rc3"
                component={RC3Main}
                layout="rc3"
                isPrivate
            />
            <Route
                path="/apps/admin"
                component={SUView}
                layout="suview"
                isPrivate
            />
            {/* 
                =================================================================
                                        MASTER ROUTES 
                =================================================================
            */}
            <Route
                path="/sysadmin"
                exact
                component={SysAdmin}
                isPrivate
                adminOnly
            />
            <Route
                path="/robottypes/:id"
                component={RobotTypeForm}
                isPrivate
                adminOnly
            />
            <Route
                path="/robottypes"
                exact
                component={RobotTypes}
                isPrivate
                adminOnly
            />
            <Route
                path="/apptypes"
                exact
                component={AppTypes}
                isPrivate
                adminOnly
            />
            <Route
                path="/apptypes/:id"
                component={AppTypeForm}
                isPrivate
                adminOnly
            />
            <Route
                path="/platform_tools"
                exact
                component={PlatformTools}
                isPrivate
                adminOnly
            />
            <Route
                path="/platform_tools/:id"
                component={PlatformToolForm}
                isPrivate
                adminOnly
            />
            <Route
                path="/platform_modules"
                exact
                component={PlatformModules}
                isPrivate
                adminOnly
            />
            <Route
                path="/platform_modules/:id"
                component={PlatformModulesForm}
                isPrivate
                adminOnly
            />
            <Route
                path="/extractions"
                exact
                component={Extractions}
                isPrivate
                adminOnly
            />
            <Route
                path="/extractions/:id"
                component={ExtractionForm}
                isPrivate
                adminOnly
            />
            <Route
                path="/visual_templates"
                exact
                component={VisualTemplates}
                isPrivate
                adminOnly
            />
            <Route
                path="/visual_templates/:id"
                component={VisualTemplateForm}
                isPrivate
                adminOnly
            />
            <Route
                path="/pluginstore/apps"
                exact
                component={Pluginstore}
                isPrivate
                adminOnly
            />
            <Route
                path="/pluginstore/apps/:id"
                component={PluginstoreForm}
                isPrivate
                adminOnly
            />
            <Route
                path="/plugintypes"
                exact
                component={PluginTypes}
                isPrivate
                adminOnly
            />
            <Route
                path="/plugintypes/:id/actions/:action_id/templates/:template_id"
                component={PluginTemplateForm}
                isPrivate
                adminOnly
            />
            <Route
                path="/plugintypes/:id/actions/:action_id"
                component={ActionForm}
                isPrivate
                adminOnly
            />
            <Route
                path="/plugintypes/:id"
                component={PluginTypeForm}
                isPrivate
                adminOnly
            />
            <Route
                path="/rc3_settings"
                exact
                component={RC3Settings}
                isPrivate
                adminOnly
            />
            <Route
                path="/robot_services"
                exact
                component={RobotServices}
                isPrivate
                adminOnly
            />
            {/* 
                =================================================================
                                        SUPERADMIN ROUTES 
                =================================================================
            */}
            <Route
                path="/admin/analytics"
                exact
                component={SUAnalytics}
                isPrivate
                adminOnly
            />
            <Route
                path="/admin/pluginspaces/:id/billing"
                component={SUBilling}
                isPrivate
                adminOnly
            />
            <Route
                path="/admin/pluginspaces/:id"
                component={SUPluginspaceForm}
                isPrivate
                adminOnly
            />
            <Route
                path="/admin/pluginspaces"
                exact
                component={SUPluginspaces}
                isPrivate
                adminOnly
            />
            <Route
                path="/admin/robots/:id"
                component={SURobotForm}
                isPrivate
                adminOnly
            />
            <Route
                path="/admin/:info"
                exact
                component={SUList}
                isPrivate
                adminOnly
            />
            <Route
                path="/robotdefaults/:id/albums/:content_id"
                component={RobotDefaultsForm}
                isPrivate
                adminOnly
            />
            <Route
                path="/robotdefaults/:id/albums"
                component={RobotDefaults}
                isPrivate
                adminOnly
            />
            <Route
                path="/robotdefaults"
                exact
                component={RobotDefaultTypes}
                isPrivate
                adminOnly
            />
            <Route
                path="/movements"
                component={RobotMovements}
                isPrivate
                adminOnly
            />
            <Route
                path="/rc3_clients"
                exact
                component={RC3Clients}
                isPrivate
                adminOnly
            />
            {/* 
                =================================================================
                                        PLUGINBOT Q&A ROUTES 
                =================================================================
            */}
            <Route
                path="/pluginbot_qna/training/:type/:id"
                component={QnATrainingForm}
                isPrivate
                adminOnly
            />
            <Route
                path="/pluginbot_qna/templates/:id"
                component={QnATemplateForm}
                isPrivate
                adminOnly
            />
            <Route
                path="/pluginbot_qna/statistics"
                component={QnAUsage}
                isPrivate
                adminOnly
            />
            <Route
                path="/pluginbot_qna/templates"
                component={QnATemplates}
                isPrivate
                adminOnly
            />
            <Route
                path="/pluginbot_qna/training"
                component={QnATraining}
                isPrivate
                adminOnly
            />
            <Route
                path="/pluginbot_qna"
                exact
                component={QnA}
                isPrivate
                adminOnly
                dark
            />
            {/* 
                =================================================================
                                        PLUGINSPACE ROUTES 
                =================================================================
            */}
            <Route
                path="/pluginspace/settings"
                component={PluginspaceForm}
                exact
                isPrivate
            />
            <Route
                path="/pluginspace/billing"
                component={PluginspaceBilling}
                exact
                isPrivate
            />
            <Route
                path="/pluginspace/locations"
                exact
                component={PluginspaceLocations}
                isPrivate
            />
            <Route
                path="/pluginspace/locations/:location_id/zones/:zone_id"
                component={PluginspaceLocationZoneForm}
                isPrivate
            />
            <Route
                path="/pluginspace/locations/:location_id"
                component={PluginspaceLocationForm}
                isPrivate
            />
            <Route
                path="/pluginspace/groups"
                exact
                component={PluginspaceGroups}
                isPrivate
            />
            <Route
                path="/pluginspace/groups/:id/users/:user_id"
                component={PluginspaceGroupUsersForm}
                isPrivate
            />
            <Route
                path="/pluginspace/groups/:id"
                component={PluginspaceGroupForm}
                isPrivate
            />
            <Route
                path="/pluginspace/users"
                exact
                component={PluginspaceUsers}
                isPrivate
            />
            <Route
                path="/pluginspace/users/:id"
                exact
                component={PluginspaceUserForm}
                isPrivate
            />
            {/* 
                =================================================================
                                        GROUP ROUTES 
                =================================================================
            */}
            <Route
                path="/groups/:id/users/:user_id"
                component={GroupUsersForm}
                isPrivate
            />
            <Route
                path="/groups/:id/users"
                exact
                component={GroupUsers}
                isPrivate
            />
            <Route
                path="/groups/:id/locations/:location_id/zones/:zone_id"
                exact
                component={GroupLocationZoneForm}
                isPrivate
                hasGroup
            />
            <Route
                path="/groups/:id/locations/:location_id"
                exact
                component={GroupLocationForm}
                isPrivate
                hasGroup
            />
            <Route
                path="/groups/:id/locations"
                exact
                component={GroupLocations}
                isPrivate
                hasGroup
            />
            <Route
                path="/groups/:id/sections/:section_id"
                exact
                component={GroupSectionsForm}
                isPrivate
            />
            <Route
                path="/groups/:id/sections"
                exact
                component={GroupSections}
                isPrivate
            />
            <Route path="/groups/:id" exact component={Page404} isPrivate />
            <Route path="/groups" exact component={MyGroups} isPrivate dark />
            {/* 
                =================================================================
                                        SECTION ROUTES 
                =================================================================
            */}
            <Route
                path="/sections/:section_id"
                exact
                component={SectionsForm}
                isPrivate
            />
            <Route path="/sections" exact component={MySections} isPrivate />
            {/* 
                =================================================================
                                        PLATFORM ROUTES 
                =================================================================
            */}
            <Route path="/dashboard" component={Dashboard} isPrivate hasGroup />

            {/* REPORTS */}
            <Route
                path="/reports/:type"
                component={Reports}
                isPrivate
                hasGroup
            />
            <Route
                path="/reports"
                exact
                component={Dashboard}
                isPrivate
                hasGroup
            />
            {/* ROBOTS */}
            <Route
                path="/robots/transfer/:id/:robot_id"
                exact
                component={TransferForm}
                isPrivate
                hasGroup
            />
            <Route
                path="/robots/transfer/:id"
                exact
                component={TransferForm}
                isPrivate
                hasGroup
            />
            <Route
                path="/robots/:id/direct"
                component={LocalCMD}
                layout="local"
                isPrivate
            />
            <Route
                path="/robots/:id"
                component={RobotForm}
                isPrivate
                hasGroup
            />
            <Route path="/robots" exact component={Robots} isPrivate hasGroup />
            {/* GALLERIES */}
            <Route
                path="/galleries"
                exact
                component={Galleries}
                isPrivate
                hasGroup
            />
            <Route
                path="/galleries/:id"
                component={GalleryForm}
                isPrivate
                hasGroup
            />
            {/* APPLICATIONS */}
            <Route
                path="/applications"
                exact
                component={Applications}
                isPrivate
                hasGroup
            />
            <Route
                path="/applications/:id/edit"
                component={AppConfig}
                isPrivate
                hasGroup
            />
            <Route
                path="/applications/:id/api"
                component={ApplicationAPIkey}
                isPrivate
                hasGroup
            />
            <Route
                path="/applications/:id"
                component={ApplicationForm}
                isPrivate
                hasGroup
            />
            {/* TOOLS */}
            <Route
                path="/tools/dialogs"
                exact
                component={Dialogs}
                isPrivate
                hasGroup
            />
            <Route
                path="/tools/qna/:id/nodes"
                component={QnANodes}
                isPrivate
                hasGroup
                layout="fullscreen"
            />
            <Route
                path="/tools/dialogs/:id/info"
                component={DialogInfo}
                isPrivate
                hasGroup
            />
            <Route
                path="/tools/dialogs/:id"
                component={DialogForm}
                isPrivate
                hasGroup
            />
            <Route
                path="/tools/presentation_contents"
                exact
                component={PresentationContents}
                isPrivate
                hasGroup
            />
            <Route
                path="/tools/presentation_contents/:id"
                component={PresentationContentForm}
                isPrivate
                hasGroup
            />
            <Route
                path="/tools/contacts"
                exact
                component={Contacts}
                isPrivate
            />
            <Route
                path="/tools/contacts/:id"
                exact
                component={ContactForm}
                isPrivate
            />
            <Route
                path="/tools/contact_lists/:id"
                exact
                component={ContactListForm}
                isPrivate
            />
            <Route
                path="/tools/scripts/:id/steps/:step_id"
                exact
                component={ScriptStepForm}
                isPrivate
                hasGroup
            />
            <Route
                path="/tools/scripts/:id"
                exact
                component={ScriptForm}
                isPrivate
                hasGroup
            />
            <Route
                path="/tools/scripts"
                exact
                component={Scripts}
                isPrivate
                hasGroup
            />
            <Route
                path="/tools/autonomous_actions/:id/actions/:action_id"
                exact
                component={AutonomousModeActions}
                isPrivate
                hasGroup
            />
            <Route
                path="/tools/autonomous_actions/:id"
                exact
                component={AutonomousModeForm}
                isPrivate
                hasGroup
            />
            <Route
                path="/tools/autonomous_actions"
                exact
                component={AutonomousMode}
                isPrivate
                hasGroup
            />
            <Route
                path="/tools/dances/:id"
                exact
                component={DanceForm}
                isPrivate
                hasGroup
            />
            <Route
                path="/tools/dances"
                exact
                component={Dances}
                isPrivate
                hasGroup
            />
            <Route
                path="/tools/info_formatters/:id"
                exact
                component={InfoFormatterPrivateForm}
                isPrivate
            />
            <Route
                path="/tools/info_formatters"
                exact
                component={InfoFormatters}
                isPrivate
            />
            <Route
                path="/tools/maps/:id/layer/:layer_id/qrcodes"
                exact
                component={NavMapLayerQR}
                isPrivate
            />
            <Route
                path="/tools/maps/:id/layer/:layer_id/points/:point_id"
                exact
                component={NavMapPointForm}
                isPrivate
            />
            <Route
                path="/tools/maps/:id/illustration"
                exact
                component={NavMapIllustration}
                isPrivate
            />
            <Route
                path="/tools/maps/:id/layer/:layer_id"
                exact
                component={NavMapLayerForm}
                isPrivate
            />
            <Route
                path="/tools/maps/:id"
                exact
                component={NavMapForm}
                isPrivate
                hasGroup
            />
            <Route
                path="/tools/maps"
                exact
                component={NavMaps}
                isPrivate
                hasGroup
            />
            <Route
                path="/tools"
                exact
                component={PluginspaceTools}
                isPrivate
                hasGroup
            />
            {/* PLUGINS */}
            <Route
                path="/plugins"
                exact
                component={PluginspacePlugins}
                isPrivate
                hasGroup
            />
            <Route
                path="/plugins/:id"
                component={PluginspacePluginconfig}
                isPrivate
            />
            <Route
                path="/pluginconfig/:plugin_id/answers/:id/:answer_id"
                component={PluginAnswersForm}
                isPrivate
                hasGroup
            />
            <Route
                path="/pluginconfig/:plugin_id/answers/:id"
                component={PluginAnswers}
                isPrivate
                hasGroup
            />
            <Route
                path="/pluginconfig/:plugin_id/survey/:id/:step_id"
                component={PluginSurveyStepForm}
                isPrivate
                hasGroup
            />
            <Route
                path="/pluginconfig/:plugin_id/survey/:id"
                component={PluginSurveySteps}
                isPrivate
                hasGroup
            />
            <Route
                path="/pluginconfig/:plugin_id/dances/:id"
                component={PluginDances}
                isPrivate
                hasGroup
            />
            <Route
                path="/recycle_bin"
                component={RecycleBin}
                isPrivate
                hasGroup
            />
            <Route path="/profile" exact component={Profile} isPrivate />
            <Route path="/home" component={Dashboard} isPrivate hasGroup />
            <Route path="/" exact component={Dashboard} isPrivate hasGroup />
            <Route path="/" isPrivate component={Page404} />
        </Switch>
    );
}
