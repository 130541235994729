/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdPlayCircleFilled } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    FormGroup,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

export default function ApplicationForm(props) {
    const dispatch = useDispatch();
    const { match } = props;
    const { id } = match.params;
    const [operation, setOperation] = useState('create');

    const [body, setBody] = useState({
        name: '',
        description: '',
    });
    const [appTypes, setAppTypes] = useState([]);
    const [appMode, setAppMode] = useState('');

    const [availableRobots, setAvailableRobots] = useState([]);
    const [selectedRobots, setSelectedRobots] = useState({});

    const [forbidden, setForbidden] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadAppTypes() {
        await api
            .get(`types?group=application`)
            .then(response => {
                setAppTypes(
                    response.data.map(a => {
                        const { settings } = a;
                        return {
                            id: a.id,
                            name: a.name,
                            slug: a.slug,
                            mode:
                                settings && settings.multiRobot
                                    ? 'multi'
                                    : 'single',
                        };
                    })
                );
            })
            .catch(error => requestError(error));
    }

    async function loadAvailableRobots(_id) {
        await api
            .get(`types/${_id}/robots`)
            .then(response => {
                const { data } = response;
                setAvailableRobots(data.robots || []);
            })
            .catch(error => requestError(error));
    }

    async function loadSettings(_id) {
        if (_id === 'new') {
            setOperation('create');
            setIsLoading(false);
        } else {
            setOperation('update');
            await api
                .get(`applications/${_id}`)
                .then(async response => {
                    const a = response.data;
                    setBody({
                        name: a.name,
                        description: a.description,
                        apptype_id: a.app_type.id,
                    });

                    const a_robots = {};
                    if (a.robot_types) {
                        a.robot_types.forEach(r => {
                            a_robots[r.id] = true;
                        });
                    }

                    setSelectedRobots(a_robots);
                    await loadAvailableRobots(a.app_type.id);

                    setTimeout(() => {
                        setIsLoading(false);
                    }, 100);
                })
                .catch(error => requestError(error));
        }
    }

    function handleAppTypeSelection(val) {
        if (val) {
            const app = appTypes.find(a => {
                return a.id === val;
            });
            setAppMode(app ? app.mode : 'single');
            loadAvailableRobots(val);
        } else {
            setAvailableRobots([]);
            setAppMode('single');
        }
    }

    useEffect(() => {
        loadAppTypes();
    }, []);

    useEffect(() => {
        loadSettings(id);
    }, [id]);

    useEffect(() => {
        handleAppTypeSelection(body.apptype_id);
    }, [body.apptype_id]);

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        const data = {
            ...body,
            mode: appMode,
            app_robot_types: selectedRobots,
        };

        if (operation === 'create') {
            await api
                .post(`applications`, data)
                .then(response => {
                    toast.success(
                        <LocaleMessage msg="page.applications.form.create_success" />
                    );

                    history.push(`/applications/${response.data.id}/edit`);
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            await api
                .put(`applications/${id}`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.applications.form.update_success" />
                    );
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        }
    }

    function handleSingleRobotChange(event) {
        const robot_id = event.target.value;
        setSelectedRobots({
            [robot_id]: event.target.checked,
        });
    }

    function handleRobotCheckboxChange(event) {
        const robot_id = event.target.value;
        setSelectedRobots({
            ...selectedRobots,
            [robot_id]: event.target.checked,
        });
    }

    function renderRobotSelector(robots) {
        return (
            <div className="col-12 mb-3">
                <FormControl component="fieldset">
                    <FormLabel>
                        <LocaleMessage msg="label.form.robot_type" />
                    </FormLabel>
                    <FormHelperText>
                        {appMode === 'multi' ? (
                            <LocaleMessage msg="page.applications.form.label.multi" />
                        ) : (
                            <LocaleMessage msg="page.applications.form.label.single" />
                        )}
                    </FormHelperText>
                    <FormGroup>
                        {availableRobots.map(r => {
                            return (
                                <FormControlLabel
                                    key={r.id}
                                    control={
                                        <Checkbox
                                            color="primary"
                                            value={r.id}
                                            disabled={
                                                operation === 'update' &&
                                                appMode === 'single'
                                            }
                                            checked={
                                                robots[r.id]
                                                    ? robots[r.id]
                                                    : false
                                            }
                                            onChange={event =>
                                                appMode === 'multi'
                                                    ? handleRobotCheckboxChange(
                                                          event
                                                      )
                                                    : handleSingleRobotChange(
                                                          event
                                                      )
                                            }
                                        />
                                    }
                                    label={r.name}
                                />
                            );
                        })}
                    </FormGroup>
                </FormControl>
            </div>
        );
    }

    return (
        <PageContent
            title={
                operation === 'create' ? (
                    <LocaleMessage msg="page.applications.form.create.title" />
                ) : (
                    <LocaleMessage msg="page.applications.form.edit.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/applications',
                    title: <LocaleMessage msg="breadcrumbs.applications" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <form className="row full-body" noValidate autoComplete="off">
                <div className="col-md-8 col-12 mb-5">
                    <CardSideBordered
                        title={
                            <LocaleMessage msg="page.applications.form.label.settings" />
                        }
                        Icon={MdPlayCircleFilled}
                    >
                        <>
                            <div className="row">
                                <div className="col-md-6 col-12 mb-5">
                                    <TextField
                                        id="application-name"
                                        label={
                                            <LocaleMessage msg="page.applications.form.label.app_name" />
                                        }
                                        fullWidth
                                        value={body.name}
                                        onChange={event =>
                                            setBody({
                                                ...body,
                                                name: event.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div className="col-md-6 col-12 mb-5">
                                    <FormControl fullWidth>
                                        <InputLabel>
                                            <LocaleMessage msg="page.applications.form.label.app_type" />
                                        </InputLabel>
                                        <Select
                                            value={
                                                body.apptype_id
                                                    ? body.apptype_id
                                                    : ''
                                            }
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    apptype_id:
                                                        event.target.value,
                                                })
                                            }
                                            disabled={operation === 'update'}
                                        >
                                            {appTypes.length > 0 ? (
                                                appTypes.map(type => (
                                                    <MenuItem
                                                        value={type.id}
                                                        key={type.id}
                                                    >
                                                        <LocaleMessage
                                                            msg={`apps.${type.slug}`}
                                                        />
                                                    </MenuItem>
                                                ))
                                            ) : (
                                                <MenuItem value={null}>
                                                    <LocaleMessage msg="page.applications.form.label.no_apptype" />
                                                </MenuItem>
                                            )}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            {body && body.apptype_id
                                ? renderRobotSelector(selectedRobots)
                                : null}
                            <div className="row">
                                <div className="col-md-12 col-12 mb-5">
                                    <TextField
                                        id="application-description"
                                        label={
                                            <LocaleMessage msg="page.applications.form.label.description" />
                                        }
                                        value={
                                            body.description
                                                ? body.description
                                                : ''
                                        }
                                        onChange={event =>
                                            setBody({
                                                ...body,
                                                description: event.target.value,
                                            })
                                        }
                                        fullWidth
                                        multiline
                                    />
                                </div>
                            </div>
                        </>
                    </CardSideBordered>
                </div>

                <div className="col-md-8 col-12 mb-5">
                    <Button
                        className="p-3"
                        variant="contained"
                        color="primary"
                        onClick={event => handleSubmit(event)}
                        fullWidth
                        size="large"
                    >
                        <LocaleMessage msg="button.save" />
                    </Button>
                </div>
            </form>
        </PageContent>
    );
}

ApplicationForm.propTypes = {
    match: PropTypes.object.isRequired,
};
