/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdDelete, MdSettings, MdDeleteSweep, MdVpnKey } from 'react-icons/md';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { formatDistance } from 'date-fns';
import { useQueryState } from 'use-location-state';

import { DialogContentText, Menu, MenuItem, Tooltip } from '@material-ui/core';

import CardItem from '~/components/CardItem';
import DataFilter from '~/components/DataFilter';
import DataTable from '~/components/DataTable';
import LocaleMessage from '~/components/LocaleMessage';
import NewItemButton from '~/components/NewItem/Button';
import NewItemCard from '~/components/NewItem/Card';
import PageContent from '~/components/PageContent';
import PreFilter from '~/components/PreFilter';
import SimpleDialog from '~/components/SimpleDialog';
import ViewSwitcher from '~/components/ViewSwitcher';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';
import getDateLocale from '~/util/GetDateLocale';
import lng_labels from '~/util/LangMessages';

const PAGE_KEY = 'applications';
const PAGE_VIEW_DEF = 'grid';

export default function Applications() {
    const dispatch = useDispatch();

    const _settings = useSelector(state => state.settings || null);
    const page_settings = useSelector(state => state.pages || null);
    const curr_page_settings =
        page_settings[PAGE_KEY] && typeof page_settings[PAGE_KEY] === 'object'
            ? page_settings[PAGE_KEY]
            : {};

    const date_loc = getDateLocale(_settings);

    const { lang } = date_loc;
    const lng_all = lng_labels[lang];
    const { active } = _settings;
    const allowNew = !(active && active.id === '*');

    const [view, setView] = useState(
        curr_page_settings && curr_page_settings.view
            ? curr_page_settings.view
            : PAGE_VIEW_DEF
    );

    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);

    const [queryFilter, setQueryFilter] = useQueryState('app_type', '');
    const [prefilter, setPrefilter] = useState(
        queryFilter ? { key: 'app_type', value: queryFilter } : null
    );

    const [rawData, setRawData] = useState([]);
    const [currItem, setCurrItem] = useState(null);
    const [filteredData, setFilteredData] = useState(rawData);
    const [apptypeFilters, setApptypeFilters] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);
    const [dialogOpen, setDialogOpen] = useState('');

    const open = Boolean(anchorEl);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadData() {
        const app_filters = {};

        try {
            setIsLoading(true);

            await api
                .get(`applications`)
                .then(response => {
                    const data = response.data.map(a => {
                        const in_use = a.robots || [];
                        const robot_list = a.robot_types;
                        let robots = '';
                        robot_list.forEach((r, idx) => {
                            if (idx !== 0) {
                                robots += ` / `;
                            }
                            robots += `${r.name}`;
                        });

                        const app_type = a.app_type
                            ? a.app_type.slug
                            : 'unknown';
                        const app_type_name = lng_all[`apps.${app_type}`];

                        if (!app_filters[app_type]) {
                            app_filters[app_type] = {
                                value: app_type,
                                label: app_type_name,
                            };
                        }
                        const updated = new Date(a.updated);

                        const robot_names = in_use.map(r => {
                            return `[${r.code}] ${r.name}`;
                        });
                        const robot_tooltip = (
                            <>
                                {robot_names.map(r => {
                                    return (
                                        <React.Fragment key={`app_robot_${r}`}>
                                            <p />
                                            {r}
                                        </React.Fragment>
                                    );
                                })}
                                <p />
                            </>
                        );
                        return {
                            ...a,
                            group_id: a.group ? a.group.id : null,
                            group_name: a.group ? (
                                a.group.name
                            ) : (
                                <LocaleMessage msg="message.all_groups.short" />
                            ),
                            app_type,
                            app_type_name,
                            robottypes: robots,
                            in_use: in_use.length || 0,
                            robot_tooltip,
                            updated: formatDistance(updated, new Date(), {
                                addSuffix: true,
                                locale: date_loc,
                            }),
                            updated_timestamp: updated.toISOString(),
                        };
                    });
                    setRawData(data);

                    const filter_array = Object.keys(app_filters).map(f => {
                        return app_filters[f];
                    });

                    setApptypeFilters(filter_array);

                    setTimeout(() => {
                        setIsLoading(false);
                    }, 100);
                })
                .catch(error => requestError(error));
        } catch (err) {
            toast.error(<LocaleMessage msg="errors.request" />);
        }
    }

    useEffect(() => {
        loadData();
    }, [active, date_loc]);

    useEffect(() => {
        setSelectedRows([]);
    }, [filteredData]);

    function handleTypeFilter(f) {
        setPrefilter(f);
        setQueryFilter(f ? f.value : '');
        setSelectedRows([]);
    }

    function handleClick(event, _id) {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
        setCurrItem(_id);
        setDialogOpen('');
        setSelectedRows([]);
    }

    function handleMenuClose() {
        setAnchorEl(null);
        setDialogOpen('');
        setSelectedRows([]);
    }

    function handleDialogClose(event) {
        event.preventDefault();
        setDialogOpen('');
        setSelectedRows([]);
    }

    function handleDeleteMultiOpen(event) {
        setCurrItem(null);
        event.preventDefault();
        setDialogOpen('multi');
    }

    function goTo(event, _id, page) {
        event.preventDefault();
        setAnchorEl(null);
        switch (page) {
            case 'settings':
                history.push(`applications/${_id}`);
                break;
            case 'apikeys':
                history.push(`applications/${_id}/api`);
                break;
            case 'delete':
                setCurrItem(_id);
                setSelectedRows([]);
                setDialogOpen('single');
                break;
            default:
                break;
        }
    }

    function handleTableRowClick(event, id) {
        event.preventDefault();
        setCurrItem(id);
        setSelectedRows([]);
        history.push(`/applications/${id}/edit`);
    }

    async function deleteMulti() {
        const delete_list = JSON.stringify(selectedRows);
        await api
            .delete(`applications?list=${delete_list}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.applications.list.delete_success" />
                );
            })
            .catch(error => requestError(error));
    }

    async function deleteItem(item) {
        await api
            .delete(`applications/${item}`)
            .then(async () => {
                toast.success(
                    <LocaleMessage msg="page.applications.list.delete_success" />
                );
            })
            .catch(error => requestError(error));
    }

    async function deleteApps() {
        setIsLoading(true);
        if (dialogOpen === 'single') {
            await deleteItem(currItem);
        } else {
            await deleteMulti();
        }
        await loadData();
        setDialogOpen('');
        setSelectedRows([]);
        setIsLoading(false);
    }

    function itemCard(item) {
        return (
            <div className="col-md-3 col-sm-6 mb-5" key={item.id}>
                <Link to={`/applications/${item.id}/edit`}>
                    <CardItem
                        title={item.name}
                        optionsClick={event => handleClick(event, item.id)}
                        group={item.group_name}
                        updated={item.updated}
                    >
                        <>
                            <p className="card-text">{item.app_type_name}</p>
                            <p className="card-text">
                                <small>{item.robottypes}</small>
                            </p>
                            {item.in_use ? (
                                <Tooltip
                                    title={item.robot_tooltip || ''}
                                    placement="top"
                                    arrow
                                >
                                    <p className="card-text">
                                        <small>
                                            <LocaleMessage msg="page.applications.list.in_use" />
                                            {': '}
                                            {item.in_use}
                                        </small>
                                    </p>
                                </Tooltip>
                            ) : null}
                            <small className="card-text">
                                {item.description}
                            </small>
                        </>
                    </CardItem>
                </Link>
            </div>
        );
    }

    function buildGridView() {
        return (
            <>
                <div
                    className="row"
                    style={{ minHeight: '150px', paddingTop: '15px' }}
                >
                    <NewItemCard
                        disabled={!allowNew}
                        link="/applications/new"
                        text={
                            <LocaleMessage msg="page.applications.list.add" />
                        }
                    />
                    {filteredData.map(item => itemCard(item))}
                </div>
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={open}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={e => goTo(e, currItem, 'settings')}>
                        <LocaleMessage msg="button.settings" />
                    </MenuItem>
                    <MenuItem onClick={e => goTo(e, currItem, 'apikeys')}>
                        <LocaleMessage msg="button.api_keys" />
                    </MenuItem>
                    <MenuItem onClick={e => goTo(e, currItem, 'delete')}>
                        <LocaleMessage msg="page.applications.list.delete" />
                    </MenuItem>
                </Menu>
            </>
        );
    }

    function buildListView() {
        const headCells = [
            {
                id: 'app_type_name',
                label: <LocaleMessage msg="table.headers.app_type" />,
            },
            {
                id: 'group_name',
                label: <LocaleMessage msg="table.headers.group" />,
            },
            { id: 'name', label: <LocaleMessage msg="table.headers.name" /> },
            {
                id: 'robottypes',
                label: <LocaleMessage msg="table.headers.robot_type" />,
            },
            {
                id: 'in_use',
                label: <LocaleMessage msg="table.headers.in_use" />,
                tooltip: 'robot_tooltip',
            },
            {
                id: 'updated',
                label: <LocaleMessage msg="table.headers.updated" />,
                order_by: 'updated_timestamp',
            },
        ];

        const rowActions = [
            {
                id: 'settings',
                label: <LocaleMessage msg="button.settings" />,
                icon: <MdSettings />,
                action: (event, _id) => goTo(event, _id, 'settings'),
            },
            {
                id: 'apis',
                label: <LocaleMessage msg="button.api_keys" />,
                icon: <MdVpnKey />,
                action: (event, _id) => goTo(event, _id, 'apikeys'),
            },
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDelete />,
                action: (event, _id) => goTo(event, _id, 'delete'),
            },
        ];

        const selAction = [
            {
                id: 'delete',
                label: <LocaleMessage msg="button.delete" />,
                icon: <MdDeleteSweep />,
                action: handleDeleteMultiOpen,
            },
        ];

        return (
            <>
                <div
                    style={{
                        minHeight: '150px',
                        width: '100%',
                        padding: '15px',
                    }}
                >
                    <DataTable
                        headerColumns={headCells}
                        data={filteredData}
                        pageKey={`${PAGE_KEY}_list`}
                        orderDirection="asc"
                        orderColumn="app_type"
                        setCurrDialog={_id => setCurrItem(_id)}
                        handleTableRowClick={(event, id) =>
                            handleTableRowClick(event, id)
                        }
                        rowActions={rowActions}
                        hasHeader
                        header={
                            <>
                                <div
                                    className="col-3"
                                    style={{ padding: '0px' }}
                                >
                                    <NewItemButton
                                        disabled={!allowNew}
                                        link="/applications/new"
                                        text={
                                            <LocaleMessage msg="page.applications.list.add" />
                                        }
                                    />
                                </div>
                            </>
                        }
                        sortable
                        selectable
                        selectedActions={selAction}
                        selectedRows={selectedRows}
                        setSelectedRows={s => {
                            setSelectedRows(s);
                        }}
                    />
                </div>
            </>
        );
    }

    function buildDeleteDialog() {
        return (
            <SimpleDialog
                open={!!dialogOpen}
                onClose={handleDialogClose}
                title={
                    <LocaleMessage msg="page.applications.list.delete_title" />
                }
                content={
                    <DialogContentText>
                        <LocaleMessage msg="message.recycler.content" />
                    </DialogContentText>
                }
                actions={[
                    {
                        key: 'cancel',
                        onClick: () => setDialogOpen(false),
                        label: <LocaleMessage msg="button.cancel" />,
                    },
                    {
                        key: 'remove',
                        onClick: () => deleteApps(),
                        label: <LocaleMessage msg="button.remove" />,
                    },
                ]}
            />
        );
    }

    return (
        <PageContent
            title={<LocaleMessage msg="page.applications.title" />}
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            <>
                {dialogOpen ? buildDeleteDialog() : null}
                <PreFilter
                    title=""
                    filterKey="app_type"
                    options={apptypeFilters}
                    defaultValue={prefilter}
                    onFilterChange={f => {
                        handleTypeFilter(f);
                    }}
                />
                <div className="body-top-controls">
                    <div className="col-3 pl-0">
                        <DataFilter
                            rawData={rawData}
                            prefilter={prefilter || null}
                            headerColumns={['group_name', 'name', 'robottypes']}
                            returnFilteredData={f_data =>
                                setFilteredData(f_data)
                            }
                        />
                    </div>
                    <ViewSwitcher
                        size="col-3"
                        view={view}
                        pageKey={PAGE_KEY}
                        setView={v => {
                            setView(v);
                            setSelectedRows([]);
                        }}
                    />
                </div>
                {view === 'grid' ? buildGridView() : buildListView()}
            </>
        </PageContent>
    );
}
