/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { MdSettings, MdModeEdit } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    InputAdornment,
    Input,
    IconButton,
} from '@material-ui/core';

import CardSideBordered from '~/components/CardSideBordered';
import CustomDialogTitle from '~/components/CustomDialogTitle';
// import LicenseForm from '~/components/LicenseForm';
import FormCheckList from '~/components/Form/CheckList';
import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';
import UserForm from '~/components/UserForm';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

import PluginspaceModules from './ModuleList';
import PluginspacePlugins from './PluginList';
import PluginspaceTools from './ToolList';

const ps_langs = [
    {
        name: 'English',
        code: 'en_GB',
    },
    {
        name: 'English (US)',
        code: 'en_US',
    },
    {
        name: 'Português',
        code: 'pt_BR',
    },
    {
        name: 'Español',
        code: 'es_ES',
    },
];

export default function PluginspaceForm(props) {
    const dispatch = useDispatch();
    const { match } = props;
    const { id } = match.params;
    const operation = id === 'new' ? 'create' : 'update';

    const [body, setBody] = useState({});
    const [, setLicense] = useState('new');

    const [adminUser, setAdminUser] = useState({});
    const [types, setTypes] = useState({});
    const [availableLanguages, setAvailableLanguages] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [adminUserOpen, setAdminUserOpen] = useState(false);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            toast.error(message);
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            }
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadLanguages() {
        await api
            .get(`languages`)
            .then(response => {
                const langs = response.data;
                setAvailableLanguages(langs);
            })
            .catch(err => requestError(err));
    }

    async function loadTypes() {
        setIsLoading(true);
        await api
            .get(`types`)
            .then(response => {
                const { data } = response;

                const p_types = {};
                const plugins = data.filter(t => {
                    return t.group === 'plugin';
                });
                p_types.plugins = plugins;

                const tools = data.filter(t => {
                    return t.group === 'tool';
                });
                p_types.tools = tools;

                const modules = data.filter(t => {
                    return t.group === 'module';
                });
                p_types.modules = modules;

                setTypes(p_types);
            })
            .catch(error => {
                requestError(error);
            });
    }

    async function loadPluginspace(_id) {
        if (operation === 'update') {
            setIsLoading(true);
            await api
                .get(`admin/pluginspaces/${_id}`)
                .then(async response => {
                    const p = response.data;
                    setBody({
                        active: p.active,
                        name: p.name,
                        subdomain: p.subdomain,
                        language: p.language,
                        admin: p.admin ? p.admin.email : null,
                        settings: p.settings || {},
                    });
                    if (p.license_id) {
                        await setLicense(p.license_id);
                    }
                    setIsLoading(false);
                })
                .catch(error => requestError(error));
        } else {
            setIsLoading(false);
        }
    }

    async function loadAll() {
        loadLanguages();
        await loadTypes();

        if (id !== 'new') {
            await loadPluginspace(id);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        loadAll();
    }, [id]);

    async function handleSubmit(event) {
        event.preventDefault();

        setIsLoading(true);

        if (operation === 'create') {
            const data = { ...body };
            await api
                .post(`admin/pluginspaces`, data)
                .then(response => {
                    toast.success(
                        <LocaleMessage msg="page.su_pluginspace.form.create_success" />
                    );

                    history.push(`/admin/pluginspaces/${response.data.id}`);
                })
                .catch(error => requestError(error));
        } else {
            const data = { ...body };
            await api
                .put(`admin/pluginspaces/${id}`, data)
                .then(() => {
                    toast.success(
                        <LocaleMessage msg="page.su_pluginspace.form.update_success" />
                    );
                })
                .catch(error => requestError(error));
        }

        setIsLoading(false);
    }

    async function handleRecover(event) {
        event.preventDefault();

        setIsLoading(true);
        const data = { ...body, active: true };
        await api
            .put(`admin/pluginspaces/${id}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.su_pluginspace.form.update_success" />
                );
                loadAll();
            })
            .catch(error => requestError(error));

        setIsLoading(false);
    }

    async function handleSubmitAdminUser(event) {
        event.preventDefault();

        setAdminUserOpen(false);
        setIsLoading(true);

        const data = { ...adminUser };
        await api
            .post(`admin/pluginspaces/${id}/admin`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.su_pluginspace.form.update_success" />
                );
                loadAll();
            })
            .catch(error => requestError(error));

        setIsLoading(false);
    }

    function handleAdminDialogClose(event) {
        event.preventDefault();
        setAdminUserOpen(false);
    }

    const allow_edit = operation === 'create' || body.active;

    function renderExistingUser() {
        return (
            <div className="col-md-8 col-12 mb-5">
                <TextField
                    label={<LocaleMessage msg="user.email" />}
                    value={adminUser.email || ''}
                    fullWidth
                    onChange={event =>
                        setAdminUser({
                            ...adminUser,
                            email: event.target.value,
                        })
                    }
                />
            </div>
        );
    }

    function renderNewUser() {
        return <UserForm user={adminUser} setUser={u => setAdminUser(u)} />;
    }

    function renderAdminUserDialog() {
        return (
            <div>
                <Dialog
                    open={adminUserOpen}
                    onClose={handleAdminDialogClose}
                    maxWidth="sm"
                    fullWidth
                >
                    <CustomDialogTitle
                        title={
                            <LocaleMessage msg="page.su_pluginspace.form.label.admin" />
                        }
                    />
                    <DialogContent>
                        <div
                            className="row"
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <div className="col-md-8 col-12 mb-5">
                                <FormControl fullWidth>
                                    <InputLabel>
                                        <LocaleMessage msg="user.source" />
                                    </InputLabel>
                                    <Select
                                        value={
                                            adminUser.new_user
                                                ? adminUser.new_user
                                                : false
                                        }
                                        onChange={event =>
                                            setAdminUser({
                                                ...adminUser,
                                                new_user: event.target.value,
                                            })
                                        }
                                    >
                                        <MenuItem value={false}>
                                            <LocaleMessage msg="user.source.existing" />
                                        </MenuItem>
                                        <MenuItem value>
                                            <LocaleMessage msg="user.source.new" />
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            {adminUser.new_user
                                ? renderNewUser()
                                : renderExistingUser()}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => setAdminUserOpen(false)}
                            color="primary"
                        >
                            <LocaleMessage msg="button.cancel" />
                        </Button>
                        <Button
                            onClick={e => handleSubmitAdminUser(e)}
                            color="primary"
                            autoFocus
                        >
                            <LocaleMessage msg="button.edit" />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    // function renderLicenseSettings() {
    //     return (
    //         <LicenseForm
    //             requestError={e => requestError(e)}
    //             pluginspace_id={id}
    //             license_id={license}
    //             allow_edit
    //             reload={() => loadAll()}
    //         />
    //     );
    // }

    function renderAddonButton(options) {
        const { label, icon, onClick } = options;

        return (
            <div className="body-top-controls">
                <Button
                    className="col-12"
                    onClick={onClick}
                    variant="contained"
                    color="primary"
                    startIcon={icon}
                    style={{
                        whiteSpace: 'nowrap',
                        padding: '5px 20px',
                    }}
                >
                    {label}
                </Button>
            </div>
        );
    }

    function renderPluginSettings() {
        return (
            <PluginspacePlugins
                pluginspace_id={id}
                plugin_list={types.plugins}
                requestError={e => requestError(e)}
                renderAddonButton={opt => renderAddonButton(opt)}
            />
        );
    }

    function renderToolSettings() {
        return (
            <PluginspaceTools
                pluginspace_id={id}
                tool_list={types.tools}
                requestError={e => requestError(e)}
                renderAddonButton={opt => renderAddonButton(opt)}
            />
        );
    }

    function renderModuleSettings() {
        return (
            <PluginspaceModules
                pluginspace_id={id}
                module_list={types.modules}
                requestError={e => requestError(e)}
                renderAddonButton={opt => renderAddonButton(opt)}
            />
        );
    }

    function renderLanguages() {
        const options = availableLanguages.map(l => {
            return {
                id: l.value,
                name: <LocaleMessage msg={`list.languages.${l.value}`} />,
            };
        });
        const body_settings = body && body.settings ? body.settings : {};
        const languages =
            body_settings && body_settings.app_languages
                ? body_settings.app_languages
                : [];

        return (
            <FormCheckList
                multiple
                allowStar={false}
                options={options}
                settings={{ list: languages }}
                onChange={l => {
                    setBody({
                        ...body,
                        settings: {
                            ...body_settings,
                            app_languages: l && l.list ? l.list : [],
                        },
                    });
                }}
            />
        );
    }

    return (
        <PageContent
            title={
                operation === 'create' ? (
                    <LocaleMessage msg="page.su_pluginspace.form.create.title" />
                ) : (
                    <LocaleMessage msg="page.su_pluginspace.form.edit.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/admin/analytics',
                    title: <LocaleMessage msg="breadcrumbs.analytics" />,
                },
                {
                    url: '/admin/pluginspaces',
                    title: <LocaleMessage msg="breadcrumbs.pluginspaces" />,
                },
            ]}
            loading={isLoading}
        >
            <>
                {adminUserOpen ? renderAdminUserDialog() : null}
                <form className="row full-body" noValidate autoComplete="off">
                    <div className="col-md-8 col-12 mb-5">
                        <CardSideBordered
                            title={
                                <LocaleMessage msg="page.su_pluginspace.form.label.settings" />
                            }
                            Icon={MdSettings}
                        >
                            <>
                                <div className="row">
                                    <div className="col-md-6 col-12 mb-5">
                                        <TextField
                                            label={
                                                <LocaleMessage msg="page.su_pluginspace.form.label.name" />
                                            }
                                            fullWidth
                                            value={body.name || ''}
                                            onChange={event =>
                                                setBody({
                                                    ...body,
                                                    name: event.target.value,
                                                })
                                            }
                                            disabled={!allow_edit}
                                        />
                                    </div>
                                    <div className="col-md-6 col-12 mb-5">
                                        <FormControl fullWidth>
                                            <InputLabel>
                                                <LocaleMessage msg="page.su_pluginspace.form.label.language" />
                                            </InputLabel>
                                            <Select
                                                value={body.language || ''}
                                                onChange={event =>
                                                    setBody({
                                                        ...body,
                                                        language:
                                                            event.target.value,
                                                    })
                                                }
                                                disabled={!allow_edit}
                                            >
                                                {ps_langs.map(p => (
                                                    <MenuItem
                                                        value={p.code}
                                                        key={p.code}
                                                    >
                                                        {p.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>

                                    {operation === 'update' ? (
                                        <div
                                            className="col-12 row"
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                justifyContent: 'top',
                                            }}
                                        >
                                            <div className="col-md-8 col-12 mb-5">
                                                <FormControl fullWidth>
                                                    <InputLabel>
                                                        <LocaleMessage msg="page.su_pluginspace.form.label.subdomain" />
                                                    </InputLabel>
                                                    <Input
                                                        value={
                                                            body.subdomain || ''
                                                        }
                                                        onChange={event =>
                                                            setBody({
                                                                ...body,
                                                                subdomain:
                                                                    event.target
                                                                        .value,
                                                            })
                                                        }
                                                        disabled={!allow_edit}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                .robots.pluginbot.ai
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className="col-md-8 col-12 mb-5">
                                                <TextField
                                                    label={
                                                        <LocaleMessage msg="page.su_pluginspace.form.label.admin_email" />
                                                    }
                                                    value={body.admin || ''}
                                                    fullWidth
                                                    disabled
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    style={{
                                                                        marginLeft:
                                                                            '10px',
                                                                        color:
                                                                            'rgba(0, 0, 0, 0.87)',
                                                                    }}
                                                                    onClick={() =>
                                                                        setAdminUserOpen(
                                                                            true
                                                                        )
                                                                    }
                                                                >
                                                                    <MdModeEdit />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </div>

                                            <div className="col-md-8 col-12 mb-5">
                                                <LocaleMessage msg="page.pluginspace.form.label.languages.title" />
                                                {renderLanguages()}
                                            </div>
                                        </div>
                                    ) : null}

                                    {allow_edit ? (
                                        <div className="col-12">
                                            <Button
                                                fullWidth
                                                size="large"
                                                className="p-3"
                                                variant="contained"
                                                color="primary"
                                                onClick={event =>
                                                    handleSubmit(event)
                                                }
                                            >
                                                <LocaleMessage msg="button.save" />
                                            </Button>
                                        </div>
                                    ) : (
                                        <>
                                            {operation === 'update' &&
                                            !body.active ? (
                                                <div className="col-12">
                                                    <Button
                                                        fullWidth
                                                        size="large"
                                                        className="p-3"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={event =>
                                                            handleRecover(event)
                                                        }
                                                    >
                                                        <LocaleMessage msg="button.recover" />
                                                    </Button>
                                                </div>
                                            ) : null}
                                        </>
                                    )}
                                </div>
                            </>
                        </CardSideBordered>
                    </div>
                </form>
                {operation === 'update' && body.active ? (
                    <>
                        {/* {renderLicenseSettings()} */}
                        <div className="col-12 row">
                            <div className="col-md-4 col-12 mb-5">
                                {renderToolSettings()}
                            </div>

                            <div className="col-md-4 col-12 mb-5">
                                {renderPluginSettings()}
                            </div>

                            <div className="col-md-4 col-12 mb-5">
                                {renderModuleSettings()}
                            </div>
                        </div>
                    </>
                ) : null}
            </>
        </PageContent>
    );
}

PluginspaceForm.propTypes = {
    match: PropTypes.object.isRequired,
};
