/* eslint-disable react/forbid-prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { MdLaunch } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';
import { useQueryState } from 'use-location-state';

import { Button, Divider, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import LocaleMessage from '~/components/LocaleMessage';
import Splash from '~/components/Splash/Inside';

// import history from '~/services/history';
import api from '~/services/pluginbot-api';
import getDateLocale from '~/util/GetDateLocale';

import Defaults from './QnADefaults';
import Documents from './QnADocuments';
import QnAError from './QnAError';
import ForbiddenSubjects from './QnAForbidden';
import Intents from './QnAIntents';
import Persona from './QnAPersona';
import Subjects from './QnASubjects';

const useStyles = makeStyles(theme => {
    return {
        root: {
            backgroundColor: theme.palette.background.paper,
            display: 'flex',
        },
        options: {
            border: `1px solid ${theme.palette.divider}`,
            textAlign: 'center',
            height: 'auto',
            padding: '0px',
        },
        tabs: {},
    };
});

const internal_pages = [
    {
        value: 'persona',
        label_code: 'page.dialogs.info.persona',
    },
    {
        value: 'defaults',
        label_code: 'page.dialogs.info.defaults',
    },
    {
        value: 'intents',
        label_code: 'page.dialogs.info.intents',
    },
    {
        value: 'subjects',
        label_code: 'page.dialogs.info.subjects',
    },
    {
        value: 'documents',
        label_code: 'page.dialogs.info.documents',
    },
    // {
    //     value: 'forbidden',
    //     label_code: 'page.dialogs.info.forbidden',
    // },
];

export default function QnADialogs({
    dialogSettings,
    reloadSettings,
    handleError,
}) {
    const { id } = dialogSettings;

    const _settings = useSelector(state => state.settings || null);
    const date_loc = getDateLocale(_settings);

    const classes = useStyles();

    const [queryTab, setQueryTab] = useQueryState(
        'content',
        internal_pages[0].value
    );
    const [currTab, setCurrTab] = useState(queryTab);

    const [isLoading, setIsLoading] = useState(true);
    const [allowedLanguages, setAllowedLanguages] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [qnaSettings, setQnaSettings] = useState({});

    const handleChangeTab = (event, newValue) => {
        setCurrTab(newValue);
        setQueryTab(newValue);
        return true;
    };

    function handleGoToTree() {
        const url = `/tools/qna/${id}/nodes`;
        return window.open(url, '_blank');
        // history.push(url);
    }

    async function loadLanguages() {
        await api
            .get(`languages/conversation`)
            .then(response => {
                const list = response.data;
                const langs = [];
                list.forEach(l => {
                    langs.push(l.value);
                });
                setAllowedLanguages(langs);
            })
            .catch(e => {
                handleError(e);
            });
        setIsLoading(false);
    }

    async function verifyQnASettings() {
        setIsLoading(true);
        await api
            .get(`dialogs/${id}/qna`)
            .then(response => {
                const { data } = response;
                if (
                    !data.training ||
                    !data.generative ||
                    data.contents === 0 ||
                    data.nodes === 0
                ) {
                    setHasError(true);
                }
                setQnaSettings(data);
            })
            .catch(error => handleError(error));
        setIsLoading(false);
    }

    async function updateDialog(updates) {
        setIsLoading(true);
        const data = {
            name: dialogSettings.name,
            description: dialogSettings.description,
            settings: {
                ...dialogSettings.settings,
                ...updates,
            },
        };
        await api
            .put(`dialogs/${id}`, data)
            .then(() => {
                toast.success(
                    <LocaleMessage msg="page.dialogs.form.update_success" />
                );
            })
            .catch(error => handleError(error));
        verifyQnASettings();
        reloadSettings();
        setIsLoading(false);
    }

    useEffect(() => {
        verifyQnASettings();
        loadLanguages();
    }, []);

    const page_components = {
        persona: Persona,
        defaults: Defaults,
        intents: Intents,
        subjects: Subjects,
        documents: Documents,
        forbidden: ForbiddenSubjects,
        error: QnAError,
    };

    function renderInternalPage(page) {
        const Page = page ? page_components[page] : Defaults;
        return (
            <Page
                locale={date_loc}
                settings={_settings}
                dialogSettings={dialogSettings}
                qnaSettings={qnaSettings}
                reloadQnASettings={() => verifyQnASettings()}
                answerLanguages={allowedLanguages}
                updateDialog={(k, v) => updateDialog({ [k]: v })}
                handleError={error => handleError(error)}
            />
        );
    }

    return (
        <div className="col-12 mt-3 mb-3 row">
            {isLoading ? (
                <Splash />
            ) : (
                <>
                    {hasError ? (
                        renderInternalPage('error')
                    ) : (
                        <>
                            <div
                                className={`${classes.options} col-md-2 col-12`}
                            >
                                {dialogSettings ? (
                                    <>
                                        <Tabs
                                            indicatorColor="primary"
                                            orientation="vertical"
                                            value={currTab || false}
                                            onChange={handleChangeTab}
                                            className={classes.tabs}
                                            centered
                                        >
                                            {internal_pages.map(p => {
                                                return (
                                                    <Tab
                                                        key={p.value}
                                                        label={
                                                            <LocaleMessage
                                                                msg={
                                                                    p.label_code
                                                                }
                                                            />
                                                        }
                                                        value={p.value}
                                                        icon={
                                                            p.icon ? (
                                                                <p.icon />
                                                            ) : null
                                                        }
                                                    />
                                                );
                                            })}
                                        </Tabs>
                                        <Divider />
                                        <Button
                                            className="p-3"
                                            // variant="outlined"
                                            color="primary"
                                            onClick={() => {
                                                handleGoToTree();
                                            }}
                                            fullWidth
                                        >
                                            <LocaleMessage msg="page.dialogs.info.nodes" />
                                            <MdLaunch
                                                size={20}
                                                className="ml-1"
                                            />
                                        </Button>
                                        <Divider />
                                    </>
                                ) : null}
                            </div>
                            <div
                                className="col-md-10 col-12 row"
                                style={{ flex: 1 }}
                            >
                                {renderInternalPage(currTab)}
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
}

QnADialogs.propTypes = {
    reloadSettings: PropTypes.func.isRequired,
    handleError: PropTypes.func.isRequired,
    dialogSettings: PropTypes.object.isRequired,
};
