/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import LocaleMessage from '~/components/LocaleMessage';
import PageContent from '~/components/PageContent';

import history from '~/services/history';
import api from '~/services/pluginbot-api';
import { expireSession } from '~/store/modules/auth/actions';

import ConversationApp from './ConversationApp';
// import DisinfectionApp from './DisinfectionApp';
import IndoorDeliveryApp from './IndoorDeliveryApp';
import InviteManagerApp from './InviteManagerApp';
import ScriptingApp from './ScriptingApp';
import TelepresenceApp from './TelepresenceApp';

export default function AppConfig(props) {
    const dispatch = useDispatch();
    const { match, location } = props;
    const { id } = match.params;

    const [app, setApp] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [forbidden, setForbidden] = useState(false);

    function requestError(error) {
        if (error.response) {
            const message = (
                <LocaleMessage msg={`errors.${error.response.data.code}`} />
            );
            const { status } = error.response;
            if (status === 401) {
                dispatch(expireSession());
            } else if (status === 403) {
                setForbidden(true);
            }
            toast.error(message);
        } else if (error.request) {
            toast.error(<LocaleMessage msg="errors.request" />);
        } else {
            toast.error(<LocaleMessage msg="errors.unknown" />);
        }
        setIsLoading(false);
    }

    async function loadSettings(_id) {
        await api
            .get(`applications/${_id}`)
            .then(response => {
                setApp(response.data);
            })
            .catch(error => requestError(error));

        setTimeout(() => {
            setIsLoading(false);
        }, 100);
    }

    useEffect(() => {
        loadSettings(id);
    }, [id]);

    function setToast(msg) {
        switch (msg.color) {
            case 'success':
                toast.success(msg.body);
                break;
            case 'error':
                toast.error(msg.body);
                break;
            case 'info':
                toast.info(msg.body);
                break;
            case 'warning':
                toast.warn(msg.body);
                break;
            default:
                toast(msg.body);
                break;
        }
    }

    async function updateObject(data) {
        setIsLoading(true);
        await api
            .put(`applications/${id}`, data)
            .then(() => {
                setToast({
                    color: 'success',
                    body: (
                        <LocaleMessage msg="page.applications.form.update_success" />
                    ),
                });
            })
            .catch(error => requestError(error));
        loadSettings(id);
    }

    const setupPages = {
        conversation: ConversationApp,
        // disinfection: DisinfectionApp,
        scripting: ScriptingApp,
        telepresence: TelepresenceApp,
        invite_manager: InviteManagerApp,
        indoor_delivery: IndoorDeliveryApp,
    };

    function appSettings() {
        if (!app.app_type) {
            return <></>;
        }

        const { slug } = app.app_type;

        const AppSetup = setupPages[slug || 'default'];

        if (AppSetup) {
            return (
                <AppSetup
                    location={location}
                    appSettings={app}
                    setToast={message => setToast(message)}
                    updateObject={data => updateObject(data)}
                />
            );
        }

        history.push(`/applications/${id}`);
        return <></>;
    }

    return (
        <PageContent
            title={
                app.name || (
                    <LocaleMessage msg="page.applications.form.edit.title" />
                )
            }
            breadcrumbs={[
                {
                    url: '/',
                    title: <LocaleMessage msg="breadcrumbs.home" />,
                },
                {
                    url: '/applications',
                    title: <LocaleMessage msg="breadcrumbs.applications" />,
                },
            ]}
            loading={isLoading}
            forbidden={forbidden}
        >
            {appSettings()}
        </PageContent>
    );
}

AppConfig.propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
};
